import { ReactElement, useEffect, useState } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { Layout, Menu } from 'antd';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  BookingsIcon,
  CompaniesIcon,
  DashboardIcon,
  DirectoryIcon,
  ExploreIcon,
  ListsIcon,
  LogOutIcon,
  PublicListsIcon,
  ReportsIcon,
} from '@assets/icons/sidebar';
import { Tooltip } from '@components/Tooltip';
import { useGetSelectedBookingsDate } from '@hooks/bookings/useGetSelectedBookingsDate';
import { SettingsIcon } from '@icons/sidebar/Settings';
import { User } from '@icons/User';
import { useAuth } from '@lib/auth';
import { setImpersonatedUser } from '@lib/redux.lib/slices/app/appSlice';
import { RootState, useAppDispatch } from '@lib/redux.lib/store';
import theme from '@styles/theme';

import Header from './components/Header';

const { Sider, Content } = Layout;

const getRouteMap = (selectedDate: string) => ({
  account: 'account',
  accountExplore: 'account/explore',
  accountDirectory: 'account/directory',
  accountPublicLists: 'account/public-lists',
  accountCompanies: 'account/companies',
  bookings: `bookings/${selectedDate}`,
  dashboard: 'dashboard',
  lists: 'lists',
  reports: 'reports',
});

const changeRoute = (
  route: string,
  routeKey: string,
  setSelectedKeys: (routes: string[]) => void,
  router: NextRouter
) => {
  setSelectedKeys([routeKey]);
  router.push(route);
};

const MainLayout = ({
  children,
  mainStyle,
}: {
  children: ReactElement;
  mainStyle?: React.CSSProperties;
}): ReactElement => {
  const { logout } = useAuth();
  // used for the routes
  const { projectId } = useSelector((state: RootState) => state.projects);
  // this is the user currently logged in
  const { user } = useSelector((state: RootState) => state.app);

  const impersonatedUser = useSelector(
    (state: RootState) => state.app.user.impersonatedUser
  );

  const appDispatch = useAppDispatch();

  const router = useRouter();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [collapsed, setCollapsed] = useState(true);

  const selectedDate = useGetSelectedBookingsDate();
  const routeMap = getRouteMap(selectedDate);

  // sets the Selected Keys so they appear highlighted when you navigate to other pages
  useEffect(() => {
    if (!router.isReady) return;
    let pathName = router.pathname;
    if (router.query) {
      // replace all query params from the path
      for (const queryKey in router.query) {
        pathName = pathName.replaceAll('[' + queryKey + ']/', '');
        pathName = pathName.match(/\/+\w+/gi)?.[0];
      }
    }
    if (pathName === '/bookings') pathName = `/bookings/${selectedDate}`;
    setSelectedKeys([pathName]); // fully replace on each mount
  }, [router.isReady, router.pathname, router.query, selectedDate]);

  const topMenuItems = [
    {
      label: collapsed ? '' : 'Dashboard',
      icon: (
        <Tooltip title={collapsed ? 'Dashboard' : ''}>
          <a data-dashboard>
            <DashboardIcon />
          </a>
        </Tooltip>
      ),
      key: '/dashboard',
    },
    {
      label: collapsed ? '' : 'Lists',
      icon: (
        <Tooltip title={collapsed ? 'Lists' : ''}>
          <a data-lists>
            <ListsIcon />
          </a>
        </Tooltip>
      ),
      key: '/lists',
    },
    {
      label: collapsed ? '' : 'Bookings',
      icon: (
        <Tooltip title={collapsed ? 'Bookings' : ''}>
          <a data-bookings>
            <BookingsIcon />
          </a>
        </Tooltip>
      ),
      key: `/bookings/${selectedDate}`,
    },
    // {
    //   label: collapsed ? '' : 'Reports',
    //   icon: (
    //     <Tooltip title={collapsed ? 'Reports' : ''}>
    //       <a data-reports>
    //         <ReportsIcon />
    //       </a>
    //     </Tooltip>
    //   ),
    //   key: '/reports',
    // },
  ];

  const bottomMenuItems = [
    // {
    //   label: collapsed ? '' : 'Account',
    //   icon: (
    //     <Tooltip title={collapsed ? 'Account' : ''}>
    //       <a data-account>
    //         <Avatar round={true} color={theme.palette.primary[0]} size='28' />
    //       </a>
    //     </Tooltip>
    //   ),
    //   key: '/account',
    // },
    // only admins get access to explore page
    ...(user?.profile?.realm === 'admin'
      ? [
          {
            label: collapsed ? '' : 'Explore',
            icon: (
              <Tooltip title={collapsed ? 'Explore' : ''}>
                <a data-account-explore>
                  <ExploreIcon />
                </a>
              </Tooltip>
            ),
            key: '/account/explore',
          },
        ]
      : []),
    {
      label: collapsed ? '' : 'Settings',
      icon: (
        <Tooltip title={collapsed ? 'Settings' : ''}>
          <a data-account-settings>
            <SettingsIcon />
          </a>
        </Tooltip>
      ),
      key: '/account/settings',
    },
    // {
    //   label: collapsed ? '' : 'Directory',
    //   icon: (
    //     <Tooltip title={collapsed ? 'Directory' : ''}>
    //       <a data-account-directory>
    //         <DirectoryIcon />
    //       </a>
    //     </Tooltip>
    //   ),
    //   key: '/account/directory',
    // },
    // {
    //   label: collapsed ? '' : 'Public Lists',
    //   icon: (
    //     <Tooltip title={collapsed ? 'Public Lists' : ''}>
    //       <a data-account-public-lists>
    //         <PublicListsIcon />
    //       </a>
    //     </Tooltip>
    //   ),
    //   key: '/account/public-lists',
    // },
    // {
    //   label: collapsed ? '' : 'Companies',
    //   icon: (
    //     <Tooltip title={collapsed ? 'Companies' : ''}>
    //       <a data-account-companies>
    //         <CompaniesIcon />
    //       </a>
    //     </Tooltip>
    //   ),
    //   key: '/account/companies',
    // },
    ...(impersonatedUser != null
      ? [
          {
            label: collapsed ? '' : 'Exit Impersonation',
            icon: (
              <Tooltip title={collapsed ? 'Exit Impersonation' : ''}>
                <a>
                  <User width={32} height={32} />
                </a>
              </Tooltip>
            ),
            key: 'exit-impersonation',
          },
        ]
      : []),
    {
      label: collapsed ? '' : 'Log Out',
      icon: (
        <Tooltip title={collapsed ? 'Log Out' : ''}>
          <a>
            <LogOutIcon />
          </a>
        </Tooltip>
      ),
      key: 'log-out',
    },
  ];

  return (
    <>
      <Header setCollapsed={setCollapsed} />
      <div style={{ marginTop: 65 }}>
        <Sidebar
          trigger={null}
          width={collapsed ? 73 : 166}
          iscollapsed={collapsed.toString()}
        >
          <Container>
            <Menu
              className='top-menu'
              mode='inline'
              items={topMenuItems}
              selectedKeys={selectedKeys}
              disabled={!projectId}
              onClick={(event) => {
                const key = event?.key;
                changeRoute(
                  '/' + projectId?.toString() + key,
                  key,
                  setSelectedKeys,
                  router
                );
              }}
              onContextMenu={(e) => {
                // right click
                e.preventDefault();
                e.stopPropagation();

                if (!projectId) return;
                const key = Object.keys(e.target.dataset)[0];
                const path = `/${projectId}/${routeMap[key]}`;
                window.open(
                  window.location.protocol + '//' + window.location.host + path,
                  '_blank'
                );
              }}
            />
            <Menu
              className='bottom-menu'
              mode='inline'
              items={bottomMenuItems}
              selectedKeys={selectedKeys}
              onClick={(event) => {
                const key = event?.key;
                if (key === 'log-out') logout();
                else if (key === 'exit-impersonation')
                  appDispatch(setImpersonatedUser(null));
                else {
                  changeRoute(key, key, setSelectedKeys, router);
                }
              }}
              onContextMenu={(e) => {
                // right click
                e.preventDefault();
                e.stopPropagation();
                const key = Object.keys(e.target.dataset)[0];
                const path = `/${routeMap[key]}`;
                window.open(
                  window.location.protocol + '//' + window.location.host + path,
                  '_blank'
                );
              }}
            />
          </Container>
        </Sidebar>

        <div>
          <StyledContent iscollapsed={collapsed.toString()} style={mainStyle}>
            {children}
          </StyledContent>
        </div>
      </div>
    </>
  );
};

export const getMainLayout = (page: ReactElement): ReactElement => (
  <MainLayout>{page}</MainLayout>
);

export const getMainLayoutWithCustomMainStyle =
  (customMainStyle: React.CSSProperties) =>
  // eslint-disable-next-line react/display-name
  (page: ReactElement): ReactElement => {
    return <MainLayout mainStyle={customMainStyle}>{page}</MainLayout>;
  };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 65px);
`;

const StyledContent = styled(Content)<{ iscollapsed: string }>`
  margin-left: ${(props) => (props.iscollapsed === 'true' ? '72px' : '165px')};
  padding: 32px;
  z-index: 1049;
  background: white;
`;

const Sidebar = styled(Sider)<{ iscollapsed: string }>`
  border-right: 1px solid ${(props) => props.theme.palette.greyscale[3]};

  &.ant-layout-sider {
    position: fixed;
    transition-property: position;
    z-index: 1;
    background: ${(props) => props.theme.palette.greyscale[1]};
  }

  .ant-menu {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    background: ${(props) => props.theme.palette.greyscale[1]};
    border: none;

    &.bottom-menu {
      background: ${(props) => props.theme.palette.greyscale[2]};
      border-radius: 4px 4px 0 0;
    }

    .ant-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 0 12px 9px !important;
      width: ${(props) => (props.iscollapsed === 'true' ? '46px' : 'auto')};
      height: auto;
      margin: 0 14px;
      color: ${(props) => props.theme.palette.text[0]};
      font-size: ${(props) => props.theme.palette.textFontSize[5]};
      line-height: ${(props) => props.theme.palette.textLineHeight[5]};
      font-weight: ${(props) => props.theme.palette.textFontWeight[4]};

      &:hover,
      a:hover {
        color: ${(props) => props.theme.palette.primary[0]};
        border-radius: 4px;
      }
    }

    &:not(.ant-menu-horizontal) .ant-menu-item-selected,
    .ant-menu-item:active {
      background-color: ${(props) => props.theme.palette.primary[14]};
      border-radius: 4px;

      .ant-menu-title-content {
        color: ${(props) => props.theme.palette.primary[0]};
      }

      &:not(.ant-menu-item-disabled) > .ant-menu-item-icon > svg > * {
        stroke: ${(props) => props.theme.palette.primary[0]};
      }
    }
  }

  .ant-menu-submenu-selected {
    background: ${(props) =>
      props.iscollapsed === 'true'
        ? props.theme.palette.highlight[1]
        : 'transparent'};
    color: ${(props) => props.theme.palette.primary[0]};
    border-radius: ${(props) => (props.iscollapsed === 'true' ? '4px' : '')};

    ul > .ant-menu-item-selected {
      span {
        color: ${(props) => props.theme.palette.primary[0]};
      }
    }
  }
`;

export default MainLayout;
