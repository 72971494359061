import { ReactNode } from 'react';
import type { DropdownProps } from 'antd';
import { Dropdown } from 'antd';
import styled from 'styled-components';

import { Arrow } from '@assets/icons';
import { Button } from '@components/Button';

interface Props extends DropdownProps {
  title: JSX.Element | string | ReactNode;
  buttonType?: 'link' | 'text' | 'primary' | 'default' | 'ghost' | 'dashed';
  style?: React.CSSProperties;
  onClick?: (...args) => void;
}

const DropdownWithArrow = ({
  title,
  buttonType = 'default',
  onClick,
  style,
  ...otherProps
}: Props): JSX.Element => {
  const dataTestId = otherProps?.['data-testid'] || 'dropdown-arw-btn';
  return (
    <DropdownButton {...otherProps}>
      <Button
        type={buttonType}
        onClick={onClick}
        style={style}
        data-testid={dataTestId}
      >
        <>
          {title}
          <Arrow direction='down' />
        </>
      </Button>
    </DropdownButton>
  );
};

const DropdownButton = styled(Dropdown)`
  svg {
    vertical-align: middle;
    margin-left: 8px;
  }
`;

export { DropdownWithArrow };
