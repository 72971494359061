export const SettingsIcon = ({
  stroke = 'currentColor',
}: {
  stroke?: string;
}): JSX.Element => (
  <svg
    width={28}
    height={22}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='settings-icon'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.8003 0.996246C11.2224 0.996246 11.599 1.26128 11.7417 1.65852L12.2499 3.07411C12.3306 3.29672 12.4872 3.48375 12.6921 3.60233L14.1997 4.47269C14.4045 4.59082 14.6444 4.63264 14.877 4.59074L16.3576 4.32363C16.7734 4.24803 17.1918 4.44183 17.4031 4.80783L18.2034 6.18841C18.4152 6.55342 18.3752 7.01208 18.1034 7.33489L17.132 8.48336C16.979 8.66396 16.895 8.89295 16.8949 9.12963V10.8704C16.895 11.107 16.979 11.336 17.132 11.5166L18.1034 12.6651C18.3752 12.9879 18.4152 13.4466 18.2034 13.8116L17.4031 15.1922C17.192 15.5578 16.7741 15.7515 16.3586 15.6764L14.878 15.4092C14.6454 15.3674 14.4055 15.4092 14.2007 15.5273L12.6931 16.3977C12.4882 16.5162 12.3316 16.7033 12.2509 16.9259L11.7427 18.3415C11.5999 18.7391 11.2228 19.0041 10.8003 19.0037H9.19965C8.77757 19.0037 8.40093 18.7387 8.25826 18.3415L7.75005 16.9259C7.66941 16.7036 7.51326 16.5166 7.30887 16.3977L5.80024 15.5273C5.59551 15.4092 5.35557 15.3674 5.12296 15.4092L3.64234 15.6764C3.22657 15.752 2.80814 15.5582 2.5969 15.1922L1.79657 13.8116C1.58477 13.4466 1.62479 12.9879 1.89661 12.6651L2.86802 11.5166C3.02101 11.336 3.10502 11.107 3.10512 10.8704V9.12963C3.10502 8.89295 3.02101 8.66396 2.86802 8.48336L1.90662 7.33489C1.6348 7.01208 1.59477 6.55342 1.80657 6.18841L2.60691 4.80783C2.81801 4.44222 3.23591 4.24849 3.65134 4.32363L5.13196 4.59074C5.36458 4.63264 5.60451 4.59082 5.80924 4.47269L7.31787 3.60233C7.52227 3.48338 7.67841 3.29643 7.75905 3.07411L8.26727 1.65852C8.4088 1.26436 8.78087 1.00008 9.19965 0.996246H10.8003Z'
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.7511 10C12.7511 11.5194 11.5194 12.7511 9.99999 12.7511C8.48057 12.7511 7.24884 11.5194 7.24884 10C7.24884 8.48058 8.48057 7.24885 9.99999 7.24885C11.5194 7.24885 12.7511 8.48058 12.7511 10Z'
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
